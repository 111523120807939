<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout id="components-layout-demo-custom-trigger">
        <Sider />
        <a-layout class="section">
          <a-layout-content
            :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }"
          >
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {};
  },
  components: {
    Header,
    Sider,
  },
};
</script>
<style scoped>
.ant-layout-has-sider{display: flex;flex-direction: row!important;justify-content: start;}
</style>